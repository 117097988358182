import { memo } from 'react'

import Drawer from 'components/atoms/Drawer'
import Content from 'components/drawers/Contact/Content'
import { useContactName } from 'hooks/Contact/useContactName'
import { useDrawer } from 'providers/Drawer'

export enum ContactDrawerTab {
  details = 'details',
}

function Contact() {
  const { id } = useDrawer()
  const { name } = useContactName(id)

  return (
    <Drawer
      open
      drawerWidth={'1200px'}
      helmetTitle={name && name}
      content={Content}
    />
  )
}

export default memo(Contact)
