export enum ROLES {
  ADMIN = 'ADMIN',
  MAESTRO = 'MAESTRO',
  COACH = 'COACH',
  MANDATORY = 'MANDATORY',
}

export type RolesType =
  | ROLES.ADMIN
  | ROLES.MAESTRO
  | ROLES.COACH
  | ROLES.MANDATORY
