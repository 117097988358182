import { memo } from 'react'

import Avatar, { AvatarProps } from 'components/atoms/Avatar'
import SkeletonAvatar from 'components/atoms/Skeleton/Avatar'
import { useUserEmail } from 'hooks/User/useUserEmail'
import { useUserFirstName } from 'hooks/User/useUserFirstName'
import { useUserLastName } from 'hooks/User/useUserLastName'
import { useUserPictureUrl } from 'hooks/User/useUserPictureUrl'
import { hashStringToColor } from 'utils/colors'

const UserAvatar = ({
  userId,
  style,
  ...rest
}: AvatarProps & {
  userId?: string
}) => {
  const { firstName, isLoading: isLoadingFirstName } = useUserFirstName(userId)
  const { lastName, isLoading: isLoadingLastName } = useUserLastName(userId)
  const { email, isLoading: isLoadingEmail } = useUserEmail(userId)
  const { pictureUrl, isLoading: isLoadingPictureUrl } =
    useUserPictureUrl(userId)

  const avatar =
    (firstName + ' ' + lastName)
      ?.match(/\b\w/g)
      ?.join('')
      ?.slice(0, 2)
      ?.toUpperCase() || email

  return (
    <SkeletonAvatar
      loading={
        isLoadingFirstName ||
        isLoadingLastName ||
        isLoadingEmail ||
        isLoadingPictureUrl
      }
      {...rest}
    >
      <Avatar
        shape="square"
        src={pictureUrl}
        style={{
          ...style,
          backgroundColor: avatar ? hashStringToColor(avatar) : undefined,
        }}
        {...rest}
      >
        {avatar}
      </Avatar>
    </SkeletonAvatar>
  )
}

export default memo(UserAvatar)
