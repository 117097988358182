import { memo } from 'react'

import SkeletonText from 'components/atoms/Skeleton/Text'
import { useUserCode } from 'hooks/User/useUserCode'

export interface UserCodeProps {
  userId?: string
}

const UserCode = ({ userId }: UserCodeProps) => {
  const { code, isLoading } = useUserCode(userId)

  return <SkeletonText loading={isLoading}>{code}</SkeletonText>
}

export default memo(UserCode)
