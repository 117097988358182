import { createContext, useContext } from 'react'

import { ContactType } from 'config/contacts'

export type FindContactsType = {
  limit: number
  skip: number
  total: number
  data: ContactType[]
}

interface IContactsContext {
  contactsService: any
  findContacts: (params?: object) => Promise<FindContactsType>
  getContact: (id: string, params?: object) => Promise<ContactType>
  createContact: (data: ContactType, params?: object) => Promise<ContactType>
  updateContact: (
    id: string,
    data: ContactType,
    params?: object
  ) => Promise<ContactType>
  removeContact: (id: string, params?: object) => Promise<ContactType>
}

export const ContactsContext = createContext<IContactsContext>({
  contactsService: {},
  findContacts: () =>
    Promise.resolve({
      limit: 0,
      skip: 0,
      total: 0,
      data: [],
    }),
  getContact: () => Promise.resolve({}),
  createContact: () => Promise.resolve({}),
  updateContact: () => Promise.resolve({}),
  removeContact: () => Promise.resolve({}),
})

export const useContacts = (): IContactsContext => useContext(ContactsContext)
