import { memo } from 'react'

import SkeletonText from 'components/atoms/Skeleton/Text'
import UserCode from 'components/molecules/User/Code'
import { useUserFirstName } from 'hooks/User/useUserFirstName'
import { useUserLastName } from 'hooks/User/useUserLastName'
import { useUser } from 'providers/User'

export interface UserNameProps {
  userId?: string
  withId?: boolean
  withLink?: boolean
}

const UserName = ({ userId, withId, withLink }: UserNameProps) => {
  const { user } = useUser()
  const { firstName, isLoading: isLoadingFirstName } = useUserFirstName(userId)
  const { lastName, isLoading: isLoadingLastName } = useUserLastName(userId)

  return (
    <SkeletonText loading={isLoadingFirstName || isLoadingLastName}>
      {firstName} {lastName}
      {withId && (
        <div className="text-xs text-[#939393]">
          ID. <UserCode userId={userId} /> {user?.id === userId && '(vous)'}
        </div>
      )}
    </SkeletonText>
  )
}

export default memo(UserName)
