import { lazy } from 'react'

import { ContactDrawerTab } from 'components/drawers/Contact'
import { ImmersionDrawerTab } from 'components/drawers/Immersion'
import { MandateDrawerTab } from 'components/drawers/Mandate'
import { UserDrawerTab } from 'components/drawers/User'
import { DRAWERS } from 'config/drawers'

const Contact = lazy(() => import('components/drawers/Contact'))
const Immersion = lazy(() => import('components/drawers/Immersion'))
const Mandate = lazy(() => import('components/drawers/Mandate'))
const User = lazy(() => import('components/drawers/User'))

export const DRAWER_DEFAULT_TAB = {
  [DRAWERS.contact]: ContactDrawerTab.details,
  [DRAWERS.immersion]: ImmersionDrawerTab.details,
  [DRAWERS.mandate]: MandateDrawerTab.details,
  [DRAWERS.user]: UserDrawerTab.details,
}

export const component = {
  [DRAWERS.contact]: Contact,
  [DRAWERS.immersion]: Immersion,
  [DRAWERS.mandate]: Mandate,
  [DRAWERS.user]: User,
}
