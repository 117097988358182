import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

import { ROUTES } from 'config/routes'

const Login = lazy(() => import('routes/Auth/Content/Login'))
const ResetPassword = lazy(() => import('routes/Auth/Content/ResetPassword'))
const ResendResetPassword = lazy(
  () => import('routes/Auth/Content/ResetPassword/ResendResetPassword')
)
const SendResetPassword = lazy(
  () => import('routes/Auth/Content/ResetPassword/SendResetPassword')
)
const SuccessResetPassword = lazy(
  () => import('routes/Auth/Content/ResetPassword/SuccessResetPassword')
)
const Signup = lazy(() => import('routes/Auth/Content/Signup'))
const SignupMaestro = lazy(
  () => import('routes/Auth/Content/Signup/SignupMaestro')
)
const SuccessSignup = lazy(
  () => import('routes/Auth/Content/Signup/SuccessSignup')
)
const VerifySignup = lazy(
  () => import('routes/Auth/Content/Signup/VerifySignup')
)

export const AuthRoutes = [
  {
    path: `${ROUTES.SIGNUP}/success/:email`,
    element: <SuccessSignup />,
  },
  {
    path: `${ROUTES.SIGNUP}/mentor/:mentorId/:email`,
    element: <Signup />,
  },
  {
    path: `${ROUTES.SIGNUP}/mentor/:mentorId`,
    element: <Signup />,
  },
  {
    path: `${ROUTES.SIGNUP}/maestro`,
    element: <SignupMaestro />,
  },
  {
    path: `${ROUTES.SIGNUP}/verify/:token/:email`,
    element: <VerifySignup />,
  },
  {
    path: `${ROUTES.SIGNUP}`,
    element: <Signup />,
  },
  {
    path: `${ROUTES.RESET_PASSWORD}/success/:email`,
    element: <SuccessResetPassword />,
  },
  {
    path: `${ROUTES.RESET_PASSWORD}/expired/:email`,
    element: <ResendResetPassword />,
  },
  {
    path: `${ROUTES.RESET_PASSWORD}/:token/:email`,
    element: <ResetPassword />,
  },
  {
    path: ROUTES.RESET_PASSWORD,
    element: <SendResetPassword />,
  },
  {
    path: '/',
    element: <Login />,
  },
  { path: '*', element: <Navigate to={`/`} replace /> },
]
