import { memo } from 'react'
import { Helmet } from 'react-helmet'

import { useFormatMessage } from 'hooks/message'

export interface HelmetProps {
  title: string
}

const HelmetTitle = ({ title }: HelmetProps) => {
  const formatMessage = useFormatMessage()

  return (
    <Helmet>
      <title>Milma · {formatMessage(title)}</title>
    </Helmet>
  )
}

export default memo(HelmetTitle)
