import { createContext, useContext } from 'react'

import { AgencyType } from 'config/agencies'

export type FindAgenciesType = {
  limit: number
  skip: number
  total: number
  data: AgencyType[]
}

interface IAgenciesContext {
  agenciesService: any
  findAgencies: (params?: object) => Promise<FindAgenciesType>
  getAgency: (id: string, params?: object) => Promise<AgencyType>
  createAgency: (data: AgencyType, params?: object) => Promise<AgencyType>
  updateAgency: (
    id: string,
    data: AgencyType,
    params?: object
  ) => Promise<AgencyType>
  removeAgency: (id: string, params?: object) => Promise<AgencyType>
}

export const AgenciesContext = createContext<IAgenciesContext>({
  agenciesService: {},
  findAgencies: () =>
    Promise.resolve({
      limit: 0,
      skip: 0,
      total: 0,
      data: [],
    }),
  getAgency: () => Promise.resolve({}),
  createAgency: () => Promise.resolve({}),
  updateAgency: () => Promise.resolve({}),
  removeAgency: () => Promise.resolve({}),
})

export const useAgencies = (): IAgenciesContext => useContext(AgenciesContext)
