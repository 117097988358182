export enum SERVICES {
  ACTIVITIES = 'activities',
  AGENCIES = 'agencies',
  AUTH_MANAGEMENT = 'auth-management',
  BENEFITS = 'benefits',
  COMPLETE_MANDATES = 'complete-mandates',
  CONTACTS = 'contacts',
  IMMERSIONS = 'immersions',
  MANDATES = 'mandates',
  NETWORK = 'network',
  OPINIONS = 'opinions',
  SEARCHES = 'searches',
  SEND_MENTORSHIPS = 'send-mentorships',
  STRIPE = 'stripe',
  UPLOAD = 'upload',
  UPLOAD_PUBLIC = 'upload-public',
  USERS = 'users',
}
