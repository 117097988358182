import { createContext, useContext } from 'react'

import { ImmersionType } from 'config/immersions'

export type FindImmersionsType = {
  limit: number
  skip: number
  total: number
  data: ImmersionType[]
}

interface IImmersionsContext {
  immersionsService: any
  findImmersions: (params?: object) => Promise<FindImmersionsType>
  getImmersion: (id: string, params?: object) => Promise<ImmersionType>
  createImmersion: (
    data: ImmersionType,
    params?: object
  ) => Promise<ImmersionType>
  updateImmersion: (
    id: string,
    data: ImmersionType,
    params?: object
  ) => Promise<ImmersionType>
  removeImmersion: (id: string, params?: object) => Promise<ImmersionType>
}

export const ImmersionsContext = createContext<IImmersionsContext>({
  immersionsService: {},
  findImmersions: () =>
    Promise.resolve({
      limit: 0,
      skip: 0,
      total: 0,
      data: [],
    }),
  getImmersion: () => Promise.resolve({}),
  createImmersion: () => Promise.resolve({}),
  updateImmersion: () => Promise.resolve({}),
  removeImmersion: () => Promise.resolve({}),
})

export const useImmersions = (): IImmersionsContext =>
  useContext(ImmersionsContext)
