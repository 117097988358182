import { ReactNode, useCallback, useMemo } from 'react'

import { ImmersionType } from 'config/immersions'
import { SERVICES } from 'config/services'
import { useFeathers } from 'providers/Feathers'
import { ImmersionsContext } from 'providers/Immersions/ImmersionsContext'

export const ImmersionsProvider = ({ children }: { children: ReactNode }) => {
  const { client } = useFeathers()

  const immersionsService = client.service(SERVICES.IMMERSIONS)

  const findImmersions = useCallback(
    (params?: object) => immersionsService.find(params),
    [immersionsService]
  )

  const getImmersion = useCallback(
    (id: string, params?: object) => immersionsService.get(id, params),
    [immersionsService]
  )

  const createImmersion = useCallback(
    (data: ImmersionType, params?: object) =>
      immersionsService.create(data, params),
    [immersionsService]
  )

  const updateImmersion = useCallback(
    (id: string, data: ImmersionType, params?: object) =>
      immersionsService.patch(id, data, params),
    [immersionsService]
  )

  const removeImmersion = useCallback(
    (id: string, params?: object) => immersionsService.remove(id, params),
    [immersionsService]
  )

  const context = useMemo(
    () => ({
      immersionsService,
      findImmersions,
      getImmersion,
      createImmersion,
      updateImmersion,
      removeImmersion,
    }),
    [
      immersionsService,
      findImmersions,
      getImmersion,
      createImmersion,
      updateImmersion,
      removeImmersion,
    ]
  )

  return (
    <ImmersionsContext.Provider value={context}>
      {children}
    </ImmersionsContext.Provider>
  )
}
