import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

import { SERVICES } from 'config/services'
import { UserType } from 'config/users'
import { useUsers } from 'providers/Users'

export const useUserCode = (userId?: string) => {
  const { getUser } = useUsers()

  const result = useQuery<UserType>({
    enabled: Boolean(userId),
    queryKey: [SERVICES.USERS, userId],
    queryFn: () => getUser(userId as string),
  })

  return useMemo(
    () => ({
      ...result,
      code: result?.data?.code,
    }),
    [result]
  )
}
