import { createContext, useContext } from 'react'

import { MandateType } from 'config/mandates'

export type FindMandatesType = {
  limit: number
  skip: number
  total: number
  data: MandateType[]
}

interface IMandatesContext {
  mandatesService: any
  findMandates: (params?: object) => Promise<FindMandatesType>
  getMandate: (id: string, params?: object) => Promise<MandateType>
  createMandate: (data: MandateType, params?: object) => Promise<MandateType>
  updateMandate: (
    id: string,
    data: MandateType,
    params?: object
  ) => Promise<MandateType>
  removeMandate: (id: string, params?: object) => Promise<MandateType>
}

export const MandatesContext = createContext<IMandatesContext>({
  mandatesService: {},
  findMandates: () =>
    Promise.resolve({
      limit: 0,
      skip: 0,
      total: 0,
      data: [],
    }),
  getMandate: () => Promise.resolve({}),
  createMandate: () => Promise.resolve({}),
  updateMandate: () => Promise.resolve({}),
  removeMandate: () => Promise.resolve({}),
})

export const useMandates = (): IMandatesContext => useContext(MandatesContext)
