import { createContext, useContext } from 'react'

import { ActivityType } from 'config/activities'

export type FindActivitiesType = {
  limit: number
  skip: number
  total: number
  data: ActivityType[]
}

interface IActivitiesContext {
  activitiesService: any
  findActivities: (params?: object) => Promise<FindActivitiesType>
  getActivity: (id: string, params?: object) => Promise<ActivityType>
  createActivity: (data: ActivityType, params?: object) => Promise<ActivityType>
  updateActivity: (
    id: string,
    data: ActivityType,
    params?: object
  ) => Promise<ActivityType>
  removeActivity: (id: string, params?: object) => Promise<ActivityType>
}

export const ActivitiesContext = createContext<IActivitiesContext>({
  activitiesService: {},
  findActivities: () =>
    Promise.resolve({
      limit: 0,
      skip: 0,
      total: 0,
      data: [],
    }),
  getActivity: () => Promise.resolve({}),
  createActivity: () => Promise.resolve({}),
  updateActivity: () => Promise.resolve({}),
  removeActivity: () => Promise.resolve({}),
})

export const useActivities = (): IActivitiesContext =>
  useContext(ActivitiesContext)
