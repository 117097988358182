import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

import { ROLES } from 'config/roles'
import { ROUTES } from 'config/routes'

const NoAccess = lazy(() => import('routes/NoAccess'))
const Contacts = lazy(() => import('routes/Home/Content/Contacts'))
const Immersions = lazy(() => import('routes/Home/Content/Immersions'))
const Mandates = lazy(() => import('routes/Home/Content/Mandates'))
const Network = lazy(() => import('routes/Home/Content/Network'))
const Profile = lazy(() => import('routes/Home/Content/Profile'))
const Public = lazy(() => import('routes/Home/Content/Public'))
const Shop = lazy(() => import('routes/Home/Content/Shop'))
const Searches = lazy(() => import('routes/Home/Content/Searches'))
const Toolbox = lazy(() => import('routes/Home/Content/Toolbox'))
const Trainings = lazy(() => import('routes/Home/Content/Trainings'))
const AdminUsers = lazy(() => import('routes/Home/Content/Admin/Users'))
const AdminMandates = lazy(() => import('routes/Home/Content/Admin/Mandates'))
const AdminImmersions = lazy(
  () => import('routes/Home/Content/Admin/Immersions')
)

export const HomeRoutes = [
  {
    path: ROUTES.ADMIN_USERS,
    element: <AdminUsers />,
    roles: [ROLES.ADMIN],
  },
  {
    path: ROUTES.ADMIN_MANDATES,
    element: <AdminMandates />,
    roles: [ROLES.ADMIN],
  },
  {
    path: ROUTES.ADMIN_IMMERSIONS,
    element: <AdminImmersions />,
    roles: [ROLES.ADMIN],
  },
  {
    path: `${ROUTES.PROFILE}/:documents`,
    element: <Profile />,
  },
  {
    path: `${ROUTES.PROFILE}/:subscription`,
    element: <Profile />,
  },
  {
    path: `${ROUTES.PROFILE}/:maestro`,
    element: <Profile />,
    roles: [ROLES.MANDATORY],
  },
  {
    path: `${ROUTES.PROFILE}/:agency`,
    element: <Profile />,
    roles: [ROLES.MAESTRO],
  },
  {
    path: `${ROUTES.PROFILE}/:coaches`,
    element: <Profile />,
    roles: [ROLES.MAESTRO],
  },
  {
    path: ROUTES.PROFILE,
    element: <Profile />,
  },
  {
    path: ROUTES.NETWORK,
    element: <Network />,
    roles: [ROLES.ADMIN, ROLES.MAESTRO, ROLES.COACH, ROLES.MANDATORY],
  },
  {
    path: ROUTES.IMMERSIONS,
    element: <Immersions />,
    roles: [ROLES.ADMIN, ROLES.MAESTRO],
  },
  {
    path: ROUTES.PUBIC_MANDATES,
    element: <Public />,
    roles: [ROLES.MANDATORY],
  },
  {
    path: ROUTES.CONTACTS,
    element: <Contacts />,
    roles: [ROLES.MANDATORY],
  },
  {
    path: ROUTES.SEARCHES,
    element: <Searches />,
    roles: [ROLES.MANDATORY],
  },
  {
    path: ROUTES.MANDATES,
    element: <Mandates />,
    roles: [ROLES.ADMIN, ROLES.MANDATORY],
  },
  {
    path: ROUTES.SHOP,
    element: <Shop />,
    roles: [ROLES.ADMIN, ROLES.MAESTRO, ROLES.COACH, ROLES.MANDATORY],
  },
  {
    path: ROUTES.TRAININGS,
    element: <Trainings />,
    roles: [ROLES.ADMIN, ROLES.MAESTRO, ROLES.COACH, ROLES.MANDATORY],
  },
  {
    path: `${ROUTES.TOOLBOX}/:simulator`,
    element: <Toolbox />,
    roles: [ROLES.ADMIN, ROLES.MAESTRO, ROLES.MANDATORY],
  },
  {
    path: `${ROUTES.TOOLBOX}/:maestro`,
    element: <Toolbox />,
    roles: [ROLES.ADMIN, ROLES.MAESTRO, ROLES.MANDATORY],
  },
  {
    path: ROUTES.TOOLBOX,
    element: <Toolbox />,
    roles: [ROLES.ADMIN, ROLES.MAESTRO, ROLES.MANDATORY],
  },
  { path: '*', element: <Navigate to={`/`} replace /> },
]
