import { ReactNode, useCallback, useMemo } from 'react'

import { CompleteMandateType } from 'config/completeMandates'
import { SERVICES } from 'config/services'
import { CompleteMandatesContext } from 'providers/CompleteMandates/CompleteMandatesContext'
import { useFeathers } from 'providers/Feathers'

export const CompleteMandatesProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const { client } = useFeathers()

  const completeMandatesService = client.service(SERVICES.COMPLETE_MANDATES)

  const completeMandate = useCallback(
    (data: CompleteMandateType, params?: object) =>
      completeMandatesService.create(data, params),
    [completeMandatesService]
  )

  const context = useMemo(
    () => ({
      completeMandatesService,
      completeMandate,
    }),
    [completeMandatesService, completeMandate]
  )

  return (
    <CompleteMandatesContext.Provider value={context}>
      {children}
    </CompleteMandatesContext.Provider>
  )
}
