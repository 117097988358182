import { ReactNode, useCallback, useMemo } from 'react'

import { ContactType } from 'config/contacts'
import { SERVICES } from 'config/services'
import { ContactsContext } from 'providers/Contacts/ContactsContext'
import { useFeathers } from 'providers/Feathers'

export const ContactsProvider = ({ children }: { children: ReactNode }) => {
  const { client } = useFeathers()

  const contactsService = client.service(SERVICES.CONTACTS)

  const findContacts = useCallback(
    (params?: object) => contactsService.find(params),
    [contactsService]
  )

  const getContact = useCallback(
    (id: string, params?: object) => contactsService.get(id, params),
    [contactsService]
  )

  const createContact = useCallback(
    (data: ContactType, params?: object) =>
      contactsService.create(data, params),
    [contactsService]
  )

  const updateContact = useCallback(
    (id: string, data: ContactType, params?: object) =>
      contactsService.patch(id, data, params),
    [contactsService]
  )

  const removeContact = useCallback(
    (id: string, params?: object) => contactsService.remove(id, params),
    [contactsService]
  )

  const context = useMemo(
    () => ({
      contactsService,
      findContacts,
      getContact,
      createContact,
      updateContact,
      removeContact,
    }),
    [
      contactsService,
      findContacts,
      getContact,
      createContact,
      updateContact,
      removeContact,
    ]
  )

  return (
    <ContactsContext.Provider value={context}>
      {children}
    </ContactsContext.Provider>
  )
}
