import { createContext, useContext } from 'react'

import { CompleteMandateType } from 'config/completeMandates'

interface ICompleteMandatesContext {
  completeMandatesService: any
  completeMandate: (
    data: CompleteMandateType,
    params?: object
  ) => Promise<CompleteMandateType>
}

export const CompleteMandatesContext = createContext<ICompleteMandatesContext>({
  completeMandatesService: {},
  completeMandate: () => Promise.resolve({}),
})

export const useCompleteMandates = (): ICompleteMandatesContext =>
  useContext(CompleteMandatesContext)
