import React, { ReactNode, useCallback, useMemo } from 'react'
import { ArrayParam, useQueryParam, withDefault } from 'use-query-params'

import { DRAWERS } from 'config/drawers'
import {
  DrawerInitType,
  DrawersContext,
  DrawerType,
  TabType,
} from 'providers/Drawers/DrawersContext'
import {
  formatDrawer,
  formatDrawers,
  parseDrawers,
} from 'providers/Drawers/utils'
import { useUser } from 'providers/User'
import { dropRight } from 'utils'

export const DrawersProvider = ({ children }: { children: ReactNode }) => {
  const { isAdmin, isMaestro, isMandatory } = useUser()

  const [drawerSearchParam, setDrawerSearchParam] = useQueryParam(
    'd',
    withDefault(ArrayParam, [])
  )

  const drawers: DrawerType[] = useMemo(
    () =>
      parseDrawers(drawerSearchParam as string[]).filter(
        ({ name }: { name: string }) => {
          switch (name) {
            case DRAWERS.contact:
            case DRAWERS.mandate:
              return isAdmin || isMandatory
            case DRAWERS.immersion:
              return isAdmin || isMaestro
            default:
              return true
          }
        }
      ),
    [drawerSearchParam, isAdmin, isMaestro, isMandatory]
  )

  const openDrawer = useCallback(
    ({ name, id, tab }: DrawerInitType): void => {
      const string = formatDrawer({ name, id, tab })
      if (string) {
        setDrawerSearchParam([...drawerSearchParam, string])
      }
    },
    [setDrawerSearchParam, drawerSearchParam]
  )

  const closeDrawer = useCallback(
    (id?: string): void => {
      const result: string[] = []
      formatDrawers(
        // If no id is provided, just remove the last drawer
        id ? drawers.filter((drawer) => drawer.id !== id) : dropRight(drawers)
      ).forEach((string) => {
        result.push(string)
      })
      setDrawerSearchParam(result)
    },
    [setDrawerSearchParam, drawers]
  )

  const closeAllDrawers = useCallback((): void => {
    setDrawerSearchParam([])
  }, [setDrawerSearchParam])

  const changeTab = useCallback(
    ({ id, tab }: { id: string; tab: TabType }): void => {
      const result: string[] = []
      formatDrawers(
        drawers.map((drawer) =>
          drawer.id === id ? { ...drawer, tab } : drawer
        )
      ).forEach((string) => {
        result.push(string)
      })
      setDrawerSearchParam(result)
    },
    [setDrawerSearchParam, drawers]
  )

  const context = useMemo(
    () => ({ openDrawer, closeDrawer, closeAllDrawers, changeTab, drawers }),
    [openDrawer, closeDrawer, closeAllDrawers, changeTab, drawers]
  )

  return (
    <DrawersContext.Provider value={context}>
      {children}
    </DrawersContext.Provider>
  )
}
