import React, { useContext } from 'react'

import { ContactDrawerTab } from 'components/drawers/Contact'
import { UserDrawerTab } from 'components/drawers/User'
import { DRAWERS } from 'config/drawers'

export type TabType = ContactDrawerTab | UserDrawerTab

export type DrawerInitType = {
  name: DRAWERS
  id: string
  tab?: TabType
}

export type DrawerType = { name: DRAWERS; id: string; tab: TabType }

interface IDrawersContext {
  openDrawer: (drawer: DrawerInitType) => void
  closeDrawer: (id?: string) => void
  changeTab: (element: { id: string; tab: TabType }) => void
  closeAllDrawers: () => void
  drawers: DrawerType[]
}

export const DrawersContext = React.createContext<IDrawersContext>({
  openDrawer: () => {},
  closeDrawer: () => {},
  changeTab: () => {},
  closeAllDrawers: () => {},
  drawers: [],
})

export const useDrawers = (): IDrawersContext => useContext(DrawersContext)
