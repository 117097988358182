import chroma from 'chroma-js'
import { lch } from 'd3-color'

import { hashStringToInt } from 'utils'

export const hashStringToColor = (string: string) => {
  const hue = hashStringToInt(string, { max: 360, min: 0 })

  return lch(60, 80, hue).formatHex()
}

export const colorRange = (
  length: number,
  color: string[] = ['#B1CEFF', '#1F4B99']
) => {
  const scale = chroma.scale(color).mode('lab')

  return Array(length)
    .fill(0)
    .map((_, i) => scale(i / length).hex())
}

const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        blue: parseInt(result[3], 16),
        green: parseInt(result[2], 16),
        red: parseInt(result[1], 16),
      }
    : null
}

export const colorForBackground = (backgroundColor: string) => {
  const rgb = hexToRgb(backgroundColor)
  if (rgb && rgb.red * 0.299 + rgb.green * 0.587 + rgb.blue * 0.114 > 186) {
    return 'dark'
  }
  return 'light'
}
