import { memo } from 'react'

import Message from 'components/atoms/Intl/Message'
import SkeletonText from 'components/atoms/Skeleton/Text'
import { useUserRole } from 'hooks/User/useUserRole'

export interface UserRoleProps {
  userId?: string
}

const UserRole = ({ userId }: UserRoleProps) => {
  const { role, isLoading } = useUserRole(userId)

  return (
    <SkeletonText loading={isLoading}>
      <Message value={`roleTypes.${role}`} />
    </SkeletonText>
  )
}

export default memo(UserRole)
