import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'

import { AuthenticationType } from 'config/authentication'
import { LOCAL_STORAGE } from 'config/localStorage'
import { LoginType } from 'config/login'
import { ROLES } from 'config/roles'
import { SERVICES } from 'config/services'
import { SignupType } from 'config/signup'
import { UserType } from 'config/users'
import { useFeathers } from 'providers/Feathers'
import { UserContext } from 'providers/User/UserContext'
import { isEmptyObject, capitalizeFirstChars, isSet } from 'utils'

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const { client } = useFeathers()

  const [user, setUser] = useState<UserType | null | undefined>(undefined)

  const login = useCallback(
    ({ email, ...rest }: LoginType) =>
      client.authenticate({
        ...rest,
        email: email.trim().toLowerCase(),
        strategy: 'local',
      }),
    [client]
  )

  const signup = useCallback(
    ({
      email,
      firstName,
      lastName,
      agencyName,
      hasRealEstateExperience,
      wantAgencyImmersion,
      ...rest
    }: SignupType) =>
      client.service(SERVICES.USERS).create({
        ...rest,
        email: email.trim().toLowerCase(),
        agencyName: capitalizeFirstChars(agencyName),
        firstName: capitalizeFirstChars(firstName),
        lastName: capitalizeFirstChars(lastName),
        hasRealEstateExperience: isSet(hasRealEstateExperience)
          ? JSON.parse(hasRealEstateExperience)
          : undefined,
        wantAgencyImmersion: isSet(wantAgencyImmersion)
          ? JSON.parse(wantAgencyImmersion)
          : undefined,
      }),
    [client]
  )

  const logout = useCallback(() => {
    localStorage.removeItem(LOCAL_STORAGE.REDIRECT_AFTER_LOGIN_PATH)
    localStorage.removeItem(LOCAL_STORAGE.REDIRECT_AFTER_LOGIN_TIME)
    client.logout()
    // localStorage.clear() // Be careful this remove all the localStorage (including lang, etc...)
    // sessionStorage.clear()
    // window.location.reload()
  }, [client])

  useEffect(() => {
    // Try to authenticate with the JWT stored in localStorage
    client.reAuthenticate().catch(() => {
      setUser(null)
    })

    // On successfull login
    client.on('authenticated', ({ user }: AuthenticationType) => {
      setUser(user)
    })

    // On logout reset all all local state (which will then show the login screen)
    client.on('logout', () => {
      setUser(null)
    })
  }, [client])

  const context = useMemo(
    () => ({
      loading: user === undefined,
      user,
      setUser,
      isAuth: user ? !isEmptyObject(user) : false,
      isAdmin: Boolean(user?.isValidated) && user?.role === ROLES.ADMIN,
      isMaestro: Boolean(user?.isValidated) && user?.role === ROLES.MAESTRO,
      isCoach: Boolean(user?.isValidated) && user?.role === ROLES.COACH,
      isMandatory: Boolean(user?.isValidated) && user?.role === ROLES.MANDATORY,
      isRoleIn: (roles: ROLES[]) =>
        Boolean(user?.isValidated) && user?.role
          ? roles.includes(user?.role)
          : false,
      isRoleNotIn: (roles: ROLES[]) =>
        Boolean(user?.isValidated) &&
        Boolean(user?.role && !roles.includes(user?.role)),
      login,
      signup,
      logout,
    }),
    [user, login, signup, logout]
  )

  return <UserContext.Provider value={context}>{children}</UserContext.Provider>
}
