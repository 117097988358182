import { ReactNode, useCallback, useMemo } from 'react'

import { SendMentorshipType } from 'config/sendMentorships'
import { SERVICES } from 'config/services'
import { useFeathers } from 'providers/Feathers'
import { SendMentorshipsContext } from 'providers/SendMentorships/SendMentorshipsContext'

export const SendMentorshipsProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const { client } = useFeathers()

  const sendMentorshipsService = client.service(SERVICES.SEND_MENTORSHIPS)

  const sendMentorship = useCallback(
    (data: SendMentorshipType, params?: object) =>
      sendMentorshipsService.create(data, params),
    [sendMentorshipsService]
  )

  const context = useMemo(
    () => ({
      sendMentorshipsService,
      sendMentorship,
    }),
    [sendMentorshipsService, sendMentorship]
  )

  return (
    <SendMentorshipsContext.Provider value={context}>
      {children}
    </SendMentorshipsContext.Provider>
  )
}
