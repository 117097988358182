import { ReactNode, useMemo, useState } from 'react'

import { LOCAL_STORAGE } from 'config/localStorage'
import { IMenuContext, MenuContext } from 'providers/Menu/MenuContext'

export const MenuProvider = ({
  children,
}: {
  children: ReactNode | ((context: IMenuContext) => ReactNode)
}) => {
  const [menu, setMenu] = useState<string>(
    localStorage.getItem(LOCAL_STORAGE.MENU) ?? 'false'
  )

  const toggleMenu = () => {
    switch (localStorage.getItem(LOCAL_STORAGE.MENU) ?? 'false') {
      case 'true':
        localStorage.setItem(LOCAL_STORAGE.MENU, 'false')
        setMenu('false')
        break
      case 'false':
        localStorage.setItem(LOCAL_STORAGE.MENU, 'true')
        setMenu('true')
        break
    }
  }

  const context = useMemo(
    () => ({
      menu,
      setMenu,
      toggleMenu,
    }),
    [menu]
  )

  return (
    <MenuContext.Provider value={context}>
      {typeof children === 'function' ? children(context) : children}
    </MenuContext.Provider>
  )
}
