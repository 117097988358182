import { ReactNode, useEffect, useMemo, useState } from 'react'

import { LOCAL_STORAGE } from 'config/localStorage'
import { BlurContext } from 'providers/Blur/BlurContext'

export const BlurProvider = ({ children }: { children: ReactNode }) => {
  const [blur, setBlur] = useState<boolean>(
    localStorage.getItem(LOCAL_STORAGE.BLUR) === 'true'
  )

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE.BLUR, blur.toString())
  }, [blur])

  const toggleBlur = () => {
    setBlur((blur) => blur !== true)
  }

  const context = useMemo(
    () => ({
      setBlur,
      blur,
      toggleBlur,
    }),
    [blur]
  )

  return <BlurContext.Provider value={context}>{children}</BlurContext.Provider>
}
