import { StyleProvider } from '@ant-design/cssinjs'
import { ConfigProvider } from 'antd'
import enGB from 'antd/es/locale/en_GB'
import frFR from 'antd/es/locale/fr_FR'
import { get } from 'lodash/fp'
import { ReactNode } from 'react'
import 'dayjs/locale/fr'
import 'dayjs/locale/en-gb'

import enGBFormRules from 'providers/Antd/formRules/enGB'
import frFRFormRules from 'providers/Antd/formRules/frFR'
import { useLocale } from 'providers/Locale'

export const AntdProvider = ({ children }: { children: ReactNode }) => {
  const { locale } = useLocale()

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#EF216C',
          colorLink: '#EF216C',
          // fontFamily:
          //  'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
          fontSize: 14,
        },
      }}
      form={{
        validateMessages: get(locale, {
          enGB: enGBFormRules,
          frFR: frFRFormRules,
        }),
      }}
      locale={get(locale, { enGB, frFR })}
      prefixCls="ant"
    >
      <StyleProvider hashPriority="high">{children}</StyleProvider>
    </ConfigProvider>
  )
}
