import React, { Dispatch, SetStateAction, useContext } from 'react'

interface IBlurContext {
  blur: boolean
  setBlur: Dispatch<SetStateAction<boolean>>
  toggleBlur: () => void
}

export const BlurContext = React.createContext<IBlurContext>({
  blur: false,
  setBlur: () => {},
  toggleBlur: () => {},
})

export const useBlur = (): IBlurContext => useContext(BlurContext)
