import { ReactNode, useCallback, useMemo } from 'react'

import { MandateType } from 'config/mandates'
import { SERVICES } from 'config/services'
import { useFeathers } from 'providers/Feathers'
import { MandatesContext } from 'providers/Mandates/MandatesContext'

export const MandatesProvider = ({ children }: { children: ReactNode }) => {
  const { client } = useFeathers()

  const mandatesService = client.service(SERVICES.MANDATES)

  const findMandates = useCallback(
    (params?: object) => mandatesService.find(params),
    [mandatesService]
  )

  const getMandate = useCallback(
    (id: string, params?: object) => mandatesService.get(id, params),
    [mandatesService]
  )

  const createMandate = useCallback(
    (data: MandateType, params?: object) =>
      mandatesService.create(data, params),
    [mandatesService]
  )

  const updateMandate = useCallback(
    (id: string, data: MandateType, params?: object) =>
      mandatesService.patch(id, data, params),
    [mandatesService]
  )

  const removeMandate = useCallback(
    (id: string, params?: object) => mandatesService.remove(id, params),
    [mandatesService]
  )

  const context = useMemo(
    () => ({
      mandatesService,
      findMandates,
      getMandate,
      createMandate,
      updateMandate,
      removeMandate,
    }),
    [
      mandatesService,
      findMandates,
      getMandate,
      createMandate,
      updateMandate,
      removeMandate,
    ]
  )

  return (
    <MandatesContext.Provider value={context}>
      {children}
    </MandatesContext.Provider>
  )
}
