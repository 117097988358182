import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

import { ContactType } from 'config/contacts'
import { SERVICES } from 'config/services'
import { useContacts } from 'providers/Contacts'

export const useContactName = (contactId?: string) => {
  const { getContact } = useContacts()

  const result = useQuery<ContactType>({
    enabled: Boolean(contactId),
    queryKey: [SERVICES.CONTACTS, contactId],
    queryFn: () => getContact(contactId as string),
  })

  return useMemo(
    () => ({
      ...result,
      name: result?.data?.name,
    }),
    [result]
  )
}
