import { ReactNode, useCallback, useMemo } from 'react'

import { SERVICES } from 'config/services'
import { useFeathers } from 'providers/Feathers'
import { NetworkContext } from 'providers/Network/NetworkContext'

export const NetworkProvider = ({ children }: { children: ReactNode }) => {
  const { client } = useFeathers()

  const NetworkService = client.service(SERVICES.NETWORK)

  const getNetwork = useCallback(
    (id: string, params?: object) => NetworkService.get(id, params),
    [NetworkService]
  )

  const context = useMemo(
    () => ({
      NetworkService,
      getNetwork,
    }),
    [NetworkService, getNetwork]
  )

  return (
    <NetworkContext.Provider value={context}>
      {children}
    </NetworkContext.Provider>
  )
}
