import { createContext, ElementType, useContext } from 'react'

import { SLUGS } from 'config/slugs'

export interface WorkflowType {
  component: ElementType
  onCompleted?: (data?: any) => void
}

export interface StartWorkflowOptionsType {
  slug: SLUGS
  data?: Record<string, any>
  onCompleted?: (data?: any) => void
}

interface IWorkflowContext<T = Record<string, any>> {
  loading: boolean
  startWorkflow: (options: StartWorkflowOptionsType) => void
  closeWorkflow: () => void
  finishWorkflow: (data?: any) => void
  data?: T
}

export const WorkflowContext = createContext<IWorkflowContext>({
  loading: false,
  startWorkflow: () => {},
  closeWorkflow: () => {},
  finishWorkflow: () => {},
  data: {},
})

export const useWorkflow = (): IWorkflowContext => useContext(WorkflowContext)
