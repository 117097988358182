import { createContext, useContext } from 'react'

import { SearchType } from 'config/searches'

export type FindSearchesType = {
  limit: number
  skip: number
  total: number
  data: SearchType[]
}

interface ISearchesContext {
  searchesService: any
  findSearches: (params?: object) => Promise<FindSearchesType>
  getSearch: (id: string, params?: object) => Promise<SearchType>
  createSearch: (data: SearchType, params?: object) => Promise<SearchType>
  updateSearch: (
    id: string,
    data: SearchType,
    params?: object
  ) => Promise<SearchType>
  removeSearch: (id: string, params?: object) => Promise<SearchType>
}

export const SearchesContext = createContext<ISearchesContext>({
  searchesService: {},
  findSearches: () =>
    Promise.resolve({
      limit: 0,
      skip: 0,
      total: 0,
      data: [],
    }),
  getSearch: () => Promise.resolve({}),
  createSearch: () => Promise.resolve({}),
  updateSearch: () => Promise.resolve({}),
  removeSearch: () => Promise.resolve({}),
})

export const useSearches = (): ISearchesContext => useContext(SearchesContext)
