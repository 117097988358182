import { lazy } from 'react'
import { Route, RouteProps } from 'react-router-dom'

import { ProtectedRoute } from 'components/atoms/ProtectedRoute'
import RouterSwitch from 'components/atoms/RouterSwitch'
import Spin from 'components/atoms/Spin'
import { RolesType } from 'config/roles'
import { ROUTES } from 'config/routes'
import { useUser } from 'providers/User'
import { AuthRoutes } from 'routes/Auth/routes'
import Home from 'routes/Home'
import { HomeRoutes } from 'routes/Home/routes'

const NoAccess = lazy(() => import('routes/NoAccess'))
const Logout = lazy(() => import('routes/Auth/Content/Logout'))
const Dashboard = lazy(() => import('routes/Home/Content/Dashboard'))
const Profile = lazy(() => import('routes/Home/Content/Profile'))

function Root() {
  const { isAuth, loading, isRoleIn, user } = useUser()

  if (loading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Spin />
      </div>
    )
  }

  return (
    <RouterSwitch>
      <Route path={`${ROUTES.LOGOUT}`} element={<Logout />} />
      {!isAuth &&
        AuthRoutes.map(({ ...props }: RouteProps, index: number) => (
          <Route key={`auth-${index}`} {...props} />
        ))}
      <Route element={<ProtectedRoute />}>
        <Route element={<Home />}>
          {isAuth &&
            [
              ...HomeRoutes,
              {
                path: '/',
                element: user?.isValidated ? <Dashboard /> : <Profile />,
              },
            ].map(
              (
                {
                  roles,
                  element,
                  ...props
                }: RouteProps & {
                  roles?: RolesType[]
                },
                index: number
              ) => (
                <Route
                  key={`home-${index}`}
                  element={
                    !roles?.length || (roles?.length && isRoleIn(roles)) ? (
                      element
                    ) : (
                      <NoAccess />
                    )
                  }
                  errorElement={<NoAccess />}
                  {...props}
                />
              )
            )}
        </Route>
      </Route>
    </RouterSwitch>
  )
}

export default Root
