import { memo, useMemo, lazy, Suspense } from 'react'

import { useDrawer } from 'providers/Drawer/DrawerContext'

const Details = lazy(
  () => import('components/drawers/Immersion/Content/Details')
)

const Content = () => {
  const { tab } = useDrawer()

  const Component = useMemo(() => {
    switch (tab) {
      default:
        return Details
    }
  }, [tab])

  if (!Component) {
    return null
  }

  return (
    <Suspense fallback={null}>
      <Component />
    </Suspense>
  )
}

export default memo(Content)
