import {
  AddCircle,
  Notepad2,
  Personalcard,
  SearchZoomIn,
  User,
} from 'iconsax-react'
import { memo, useEffect, useMemo, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import LoadingBar from 'react-top-loading-bar'

import FloatButton from 'components/atoms/FloatButton'
import { SLUGS } from 'config/slugs'
import { MenuProvider } from 'providers/Menu'
import { useUser } from 'providers/User'
import { useWorkflow } from 'providers/Workflow'
import Header from 'routes/Home/Header'
import Sider from 'routes/Home/Sider'
import { cleanArray } from 'utils'

function Home() {
  const { isMandatory } = useUser()
  const navigation = useLocation()
  const { startWorkflow } = useWorkflow()

  const [progress, setProgress] = useState<number>(0)

  useEffect(() => {
    setProgress(100)
  }, [navigation])

  const floatButtons = useMemo(
    () =>
      cleanArray([
        {
          tooltip: 'word.addMentorship',
          icon: <User size={19} />,
          onClick: () =>
            startWorkflow({
              slug: SLUGS.SEND_MENTORSHIP,
            }),
        },
        isMandatory
          ? {
              tooltip: 'word.addContact',
              icon: <Personalcard size={19} />,
              onClick: () =>
                startWorkflow({
                  slug: SLUGS.EDIT_CONTACT,
                }),
            }
          : undefined,
        isMandatory
          ? {
              tooltip: 'word.addSearch',
              icon: <SearchZoomIn size={19} />,
              onClick: () =>
                startWorkflow({
                  slug: SLUGS.EDIT_SEARCH,
                }),
            }
          : undefined,
        isMandatory
          ? {
              tooltip: 'word.addMandate',
              icon: <Notepad2 size={19} />,
              onClick: () =>
                startWorkflow({
                  slug: SLUGS.EDIT_MANDATE,
                }),
            }
          : undefined,
      ]),
    [startWorkflow, isMandatory]
  )

  return (
    <MenuProvider>
      {({ menu }) => (
        <div
          className="disable-scrollbars flex w-full bg-zinc-50 antialiased"
          data-layout="layout"
          data-collapsed={menu}
        >
          <LoadingBar
            height={3}
            color="#EF216C"
            progress={progress}
            onLoaderFinished={() => setProgress(0)}
          />
          <Sider />
          <div className="main w-full bg-zinc-50 text-zinc-900">
            <Header />
            <Outlet />
          </div>
          {isMandatory ? (
            <FloatButton
              className="block sm:hidden"
              style={{ right: 50 }}
              type="primary"
              trigger="hover"
              icon={<AddCircle size={19} />}
              items={floatButtons}
            />
          ) : undefined}
        </div>
      )}
    </MenuProvider>
  )
}

export default memo(Home)
