import { createContext, useContext } from 'react'

import { OpinionType } from 'config/opinions'

export type FindOpinionsType = {
  limit: number
  skip: number
  total: number
  data: OpinionType[]
}

interface IOpinionsContext {
  opinionsService: any
  findOpinions: (params?: object) => Promise<FindOpinionsType>
  getOpinion: (id: string, params?: object) => Promise<OpinionType>
  createOpinion: (data: OpinionType, params?: object) => Promise<OpinionType>
  updateOpinion: (
    id: string,
    data: OpinionType,
    params?: object
  ) => Promise<OpinionType>
  removeOpinion: (id: string, params?: object) => Promise<OpinionType>
}

export const OpinionsContext = createContext<IOpinionsContext>({
  opinionsService: {},
  findOpinions: () =>
    Promise.resolve({
      limit: 0,
      skip: 0,
      total: 0,
      data: [],
    }),
  getOpinion: () => Promise.resolve({}),
  createOpinion: () => Promise.resolve({}),
  updateOpinion: () => Promise.resolve({}),
  removeOpinion: () => Promise.resolve({}),
})

export const useOpinions = (): IOpinionsContext => useContext(OpinionsContext)
