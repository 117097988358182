// https://ant.design/components/button/

import { Button as AntdButton } from 'antd'
import { ButtonProps as AntdButtonProps } from 'antd/es/button'
import { memo } from 'react'

import { MessageType } from 'config/message'
import { useFormatMessage } from 'hooks/message'

export interface ButtonProps extends AntdButtonProps {
  to?: string
  message?: MessageType
  myRef?: any
}

const Button = ({
  to,
  icon,
  children,
  className = '',
  message,
  myRef,
  ...rest
}: ButtonProps) => {
  const formatMessage = useFormatMessage()
  return (
    <AntdButton
      className={
        'flex min-h-[48px] items-center justify-center rounded-full shadow-sm transition duration-200 hover:shadow-md ' +
        className
      }
      icon={
        icon && (
          <span className={message ? 'mr-2' : ''}>{icon || undefined}</span>
        )
      }
      ref={myRef}
      {...rest}
    >
      {children || formatMessage(message)}
    </AntdButton>
  )
}

export default memo(Button)
