export enum LOCAL_STORAGE {
  BLUR = 'blur',
  FILTERS_CONTACTS = 'filters-contacts',
  FILTERS_MANDATES = 'filters-mandates',
  FILTERS_NETWORK = 'filters-network',
  FILTERS_PUBLIC = 'filters-public',
  FILTERS_SEARCHES = 'filters-searches',
  FILTERS_ADMIN_USERS = 'filters-admin-users',
  FILTERS_ADMIN_MANDATES = 'filters-admin-mandates',
  FILTERS_ADMIN_IMMERSIONS = 'filters-admin-immersions',
  LOCALE = 'locale',
  THEME = 'theme',
  TOKEN_ID = 'token-id',
  MENU = 'menu',
  NETWORK = 'network',
  REDIRECT_AFTER_LOGIN_PATH = 'redirect-after-login-path',
  REDIRECT_AFTER_LOGIN_TIME = 'redirect-after-login-time',
}
