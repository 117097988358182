export enum ROUTES {
  ADMIN_IMMERSIONS = 'admin-immersions',
  ADMIN_MANDATES = 'admin-mandates',
  ADMIN_USERS = 'admin-users',
  CONTACTS = 'contacts',
  IMMERSIONS = 'immersions',
  LOGIN = 'login',
  LOGOUT = 'logout',
  MANDATES = 'mandates',
  MENTORSHIPS = 'mentorships',
  NETWORK = 'network',
  PROFILE = 'profile',
  PUBIC_MANDATES = 'public-mandates',
  RESET_PASSWORD = 'reset-password',
  SEARCHES = 'searches',
  SHOP = 'shop',
  SIGNUP = 'signup',
  TOOLBOX = 'toolbox',
  TRAININGS = 'trainings',
  USERS = 'users',
}
