import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Message } from 'iconsax-react'
import NP from 'number-precision'
import { Slider } from 'react-typeform-embed'

import Space from 'components/atoms/Space'
import { ActivitiesProvider } from 'providers/Activities'
import { AgenciesProvider } from 'providers/Agencies'
import { AntdProvider } from 'providers/Antd'
import { BenefitsProvider } from 'providers/Benefits'
import { BlurProvider } from 'providers/Blur'
import { CompleteMandatesProvider } from 'providers/CompleteMandates'
import { ContactsProvider } from 'providers/Contacts'
import { DrawerProvider } from 'providers/Drawer'
import { DrawersProvider } from 'providers/Drawers'
import { FeathersProvider } from 'providers/Feathers'
import { FilePreviewUrlProvider } from 'providers/FilePreviewUrl'
import { ImmersionsProvider } from 'providers/Immersions'
import { IntlProvider } from 'providers/Intl'
import { LocaleProvider } from 'providers/Locale'
import { MandatesProvider } from 'providers/Mandates'
import { NetworkProvider } from 'providers/Network'
import { NotificationProvider } from 'providers/Notification'
import { OpinionsProvider } from 'providers/Opinions'
import { ReactErrorProvider } from 'providers/ReactError'
import { RouterProvider } from 'providers/Router'
import { SearchesProvider } from 'providers/Searches'
import { SendMentorshipsProvider } from 'providers/SendMentorships'
import { ThemeProvider } from 'providers/Theme'
import { UserProvider } from 'providers/User'
import { UsersProvider } from 'providers/Users'
import { WorkflowProvider } from 'providers/Workflow'
import Root from 'routes'
import { isDevEnv } from 'utils/env'

NP.enableBoundaryChecking(isDevEnv)
const queryClient = new QueryClient()

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      {isDevEnv && <ReactQueryDevtools />}
      <Slider style={{ position: 'absolute' }} id="Ov51fzmo">
        <button
          className="fixed -right-11 top-3/4 z-[10000] -rotate-90 rounded-t-md bg-[#EF216C] px-3 py-[2px] text-white"
          style={{
            background: 'linear-gradient(270deg, #BD1C62 0%, #EF216C 100%)',
          }}
        >
          <Space direction="horizontal">
            <Message size={14} /> Feedback
          </Space>
        </button>
      </Slider>
      <FeathersProvider>
        <UserProvider>
          <LocaleProvider>
            <IntlProvider>
              <RouterProvider>
                <ThemeProvider>
                  <BlurProvider>
                    <AntdProvider>
                      <ReactErrorProvider>
                        <UsersProvider>
                          <NetworkProvider>
                            <SendMentorshipsProvider>
                              <ContactsProvider>
                                <ActivitiesProvider>
                                  <SearchesProvider>
                                    <MandatesProvider>
                                      <CompleteMandatesProvider>
                                        <AgenciesProvider>
                                          <BenefitsProvider>
                                            <OpinionsProvider>
                                              <ImmersionsProvider>
                                                <NotificationProvider>
                                                  <DrawersProvider>
                                                    <FilePreviewUrlProvider>
                                                      <WorkflowProvider>
                                                        <DrawerProvider>
                                                          <Root />
                                                        </DrawerProvider>
                                                      </WorkflowProvider>
                                                    </FilePreviewUrlProvider>
                                                  </DrawersProvider>
                                                </NotificationProvider>
                                              </ImmersionsProvider>
                                            </OpinionsProvider>
                                          </BenefitsProvider>
                                        </AgenciesProvider>
                                      </CompleteMandatesProvider>
                                    </MandatesProvider>
                                  </SearchesProvider>
                                </ActivitiesProvider>
                              </ContactsProvider>
                            </SendMentorshipsProvider>
                          </NetworkProvider>
                        </UsersProvider>
                      </ReactErrorProvider>
                    </AntdProvider>
                  </BlurProvider>
                </ThemeProvider>
              </RouterProvider>
            </IntlProvider>
          </LocaleProvider>
        </UserProvider>
      </FeathersProvider>
    </QueryClientProvider>
  )
}

export default App
