import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

import { SERVICES } from 'config/services'
import { FindImmersionsType, useImmersions } from 'providers/Immersions'

export const useUserImmersionIds = (userId?: string, filters: object = {}) => {
  const { findImmersions } = useImmersions()

  const result = useQuery<FindImmersionsType>({
    enabled: Boolean(userId),
    queryKey: [SERVICES.IMMERSIONS, userId, ...Object.values(filters)],
    queryFn: () =>
      findImmersions({
        query: {
          ...filters,
        },
      }),
  })

  return useMemo(
    () => ({
      ...result,
      immersionIds: result?.data?.data?.map(({ id }) => id),
    }),
    [result]
  )
}
