import { memo } from 'react'

import Drawer from 'components/atoms/Drawer'
import Content from 'components/drawers/Immersion/Content'

export enum ImmersionDrawerTab {
  details = 'details',
}

function Immersion() {
  return (
    <Drawer
      open
      drawerWidth={'1200px'}
      helmetTitle={'word.immersion'}
      content={Content}
    />
  )
}

export default memo(Immersion)
