// https://ant.design/components/menu/

import { Menu as AntdMenu } from 'antd'
import { MenuItemGroupProps, MenuProps as AntdMenuProps } from 'antd/es/menu'
import { MenuItemProps } from 'antd/es/menu/MenuItem'
import { SubMenuProps } from 'antd/es/menu/SubMenu'
import { memo, ReactElement, ReactNode, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { DividerProps } from 'components/atoms/Divider'
import { MessageType } from 'config/message'
import { useFormatMessage } from 'hooks/message'
import { cleanArray } from 'utils'

const { SubMenu, Item, ItemGroup, Divider } = AntdMenu

interface IItemGroup extends Omit<MenuItemGroupProps, 'title'> {
  title: MessageType
  group: (ItemType | undefined)[]
}

interface ISubMenu extends Omit<SubMenuProps, 'title' | 'items'> {
  title: MessageType
  items: (ItemType | undefined)[]
  key: string
  to?: string
}

interface IItem extends Omit<MenuItemProps, 'title'> {
  title?: MessageType
  message?: MessageType
  key: string
  to?: string
}

export type ItemType =
  | (DividerProps & { divider: true })
  | IItemGroup
  | ISubMenu
  | IItem

export interface MenuProps extends Omit<AntdMenuProps, 'items'> {
  items?: (ItemType | undefined)[]
  myRef?: any
}

const Menu = ({ items = [], myRef, ...rest }: MenuProps) => {
  const formatMessage = useFormatMessage()
  const navigate = useNavigate()

  const formatMenu = useCallback(
    (items: ItemType[]): ReactElement[] =>
      items.map((item, index) => {
        if ('divider' in item) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { divider, ...dividerRest } = item
          return <Divider key={index} {...dividerRest} />
        }
        if ('group' in item) {
          const { title, group, ...groupRest } = item
          return (
            <ItemGroup title={formatMessage(title)} key={index} {...groupRest}>
              {formatMenu(cleanArray(group))}
            </ItemGroup>
          )
        }

        if ('items' in item) {
          const {
            title,
            items,
            to,
            onTitleClick = () => {},
            key,
            ...subMenuRest
          } = item
          return (
            <SubMenu
              title={formatMessage(title)}
              key={key}
              onTitleClick={(title) => {
                onTitleClick(title)
                if (to) {
                  navigate(to)
                }
              }}
              {...subMenuRest}
            >
              {formatMenu(cleanArray(items))}
            </SubMenu>
          )
        }

        const {
          message,
          title,
          to,
          onClick = () => {},
          key,
          ...itemRest
        } = item

        return (
          <Item
            key={key}
            title={formatMessage(title) || title}
            onClick={(info) => {
              info.domEvent.stopPropagation()
              info.domEvent.preventDefault()
              onClick(info)
              if (to) {
                navigate(to)
              }
            }}
            {...itemRest}
          >
            {formatMessage(message)}
          </Item>
        )
      }),
    [formatMessage, navigate]
  )

  if (!items.length) {
    return null
  }

  return (
    <AntdMenu ref={myRef} {...rest}>
      {formatMenu(cleanArray(items))}
    </AntdMenu>
  )
}

export default memo(Menu)
