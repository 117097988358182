import { createContext, useContext } from 'react'

import { LoginType } from 'config/login'
import { RolesType } from 'config/roles'
import { SignupType } from 'config/signup'
import { UserType } from 'config/users'

interface IUserContext {
  loading: boolean
  user: UserType | null | undefined
  setUser: React.Dispatch<React.SetStateAction<UserType | null | undefined>>
  isAuth: boolean
  isAdmin: boolean
  isMaestro: boolean
  isCoach: boolean
  isMandatory: boolean
  isRoleIn: (roles: RolesType[]) => boolean
  isRoleNotIn: (roles: RolesType[]) => boolean
  login: ({ email, password }: LoginType) => any
  signup: ({ email, password }: SignupType) => any
  logout: () => any
}

export const UserContext = createContext<IUserContext>({
  loading: false,
  user: undefined,
  setUser: () => {},
  isAuth: false,
  isAdmin: false,
  isMaestro: false,
  isCoach: false,
  isMandatory: false,
  isRoleIn: () => false,
  isRoleNotIn: () => false,
  login: () => {},
  signup: () => {},
  logout: () => {},
})

export const useUser = (): IUserContext => useContext(UserContext)
