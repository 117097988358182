// https://ant.design/components/FloatButton/

import {
  FloatButton as AntdFloatButton,
  FloatButtonProps as AntdFloatButtonProps,
  FloatButtonGroupProps as AntdFloatButtonGroupProps,
} from 'antd'
import { memo } from 'react'

import { MessageType } from 'config/message'
import { useFormatMessage } from 'hooks/message'

export interface FloatButtonGroupProps
  extends Omit<AntdFloatButtonGroupProps, 'children'> {
  items?: FloatButtonProps[]
}

export interface FloatButtonProps
  extends Omit<AntdFloatButtonProps, 'tooltip'> {
  tooltip?: MessageType
}

const FloatButton = ({ items = [], ...props }: FloatButtonGroupProps) => {
  const formatMessage = useFormatMessage()

  return (
    <AntdFloatButton.Group {...props}>
      {items.map(({ tooltip, ...item }, index) => (
        <AntdFloatButton
          key={index}
          tooltip={formatMessage(tooltip)}
          {...item}
        />
      ))}
    </AntdFloatButton.Group>
  )
}

export default memo(FloatButton)
