import { createContext, useContext } from 'react'

import { BenefitType } from 'config/benefits'

export type FindBenefitsType = {
  limit: number
  skip: number
  total: number
  data: BenefitType[]
}

interface IBenefitsContext {
  benefitsService: any
  findBenefits: (params?: object) => Promise<FindBenefitsType>
  getBenefit: (id: string, params?: object) => Promise<BenefitType>
  createBenefit: (data: BenefitType, params?: object) => Promise<BenefitType>
  updateBenefit: (
    id: string,
    data: BenefitType,
    params?: object
  ) => Promise<BenefitType>
  removeBenefit: (id: string, params?: object) => Promise<BenefitType>
}

export const BenefitsContext = createContext<IBenefitsContext>({
  benefitsService: {},
  findBenefits: () =>
    Promise.resolve({
      limit: 0,
      skip: 0,
      total: 0,
      data: [],
    }),
  getBenefit: () => Promise.resolve({}),
  createBenefit: () => Promise.resolve({}),
  updateBenefit: () => Promise.resolve({}),
  removeBenefit: () => Promise.resolve({}),
})

export const useBenefits = (): IBenefitsContext => useContext(BenefitsContext)
