import { memo } from 'react'

import Message from 'components/atoms/Intl/Message'
import SkeletonText from 'components/atoms/Skeleton/Text'
import Tag, { TagProps } from 'components/atoms/Tag'
import { ROLES } from 'config/roles'
import { useUserRole } from 'hooks/User/useUserRole'

const TagUserRole = ({
  userId,
  noSkeleton,
  ...rest
}: TagProps & {
  userId?: string
  noSkeleton?: boolean
}) => {
  const { role, isLoading } = useUserRole(userId)

  const color = role
    ? {
        [ROLES.ADMIN]: '#EF216C',
        [ROLES.MAESTRO]: '#EF216C',
        [ROLES.COACH]: '#1C5DBD',
        [ROLES.MANDATORY]: '#6EB655',
      }[role]
    : '#252525'

  const backgroundColor = role
    ? {
        [ROLES.ADMIN]: '#FFECF3',
        [ROLES.MAESTRO]: '#FFECF3',
        [ROLES.COACH]: '#F0F9FF',
        [ROLES.MANDATORY]: '#EDFCF2',
      }[role]
    : '#252525'

  return (
    <SkeletonText loading={!noSkeleton && isLoading}>
      {role && (
        <Tag
          style={{
            backgroundColor,
            color,
            border: 0,
          }}
          message={`roleTypes.${role}`}
          {...rest}
        />
      )}
    </SkeletonText>
  )
}

export default memo(TagUserRole)
