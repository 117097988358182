import { ReactNode, useCallback, useMemo } from 'react'

import { AgencyType } from 'config/agencies'
import { SERVICES } from 'config/services'
import { AgenciesContext } from 'providers/Agencies/AgenciesContext'
import { useFeathers } from 'providers/Feathers'

export const AgenciesProvider = ({ children }: { children: ReactNode }) => {
  const { client } = useFeathers()

  const agenciesService = client.service(SERVICES.AGENCIES)

  const findAgencies = useCallback(
    (params?: object) => agenciesService.find(params),
    [agenciesService]
  )

  const getAgency = useCallback(
    (id: string, params?: object) => agenciesService.get(id, params),
    [agenciesService]
  )

  const createAgency = useCallback(
    (data: AgencyType, params?: object) => agenciesService.create(data, params),
    [agenciesService]
  )

  const updateAgency = useCallback(
    (id: string, data: AgencyType, params?: object) =>
      agenciesService.patch(id, data, params),
    [agenciesService]
  )

  const removeAgency = useCallback(
    (id: string, params?: object) => agenciesService.remove(id, params),
    [agenciesService]
  )

  const context = useMemo(
    () => ({
      agenciesService,
      findAgencies,
      getAgency,
      createAgency,
      updateAgency,
      removeAgency,
    }),
    [
      agenciesService,
      findAgencies,
      getAgency,
      createAgency,
      updateAgency,
      removeAgency,
    ]
  )

  return (
    <AgenciesContext.Provider value={context}>
      {children}
    </AgenciesContext.Provider>
  )
}
