import { ReactNode, useCallback, useMemo } from 'react'

import { ActivityType } from 'config/activities'
import { SERVICES } from 'config/services'
import { ActivitiesContext } from 'providers/Activities/ActivitiesContext'
import { useFeathers } from 'providers/Feathers'

export const ActivitiesProvider = ({ children }: { children: ReactNode }) => {
  const { client } = useFeathers()

  const activitiesService = client.service(SERVICES.ACTIVITIES)

  const findActivities = useCallback(
    (params?: object) => activitiesService.find(params),
    [activitiesService]
  )

  const getActivity = useCallback(
    (id: string, params?: object) => activitiesService.get(id, params),
    [activitiesService]
  )

  const createActivity = useCallback(
    (data: ActivityType, params?: object) =>
      activitiesService.create(data, params),
    [activitiesService]
  )

  const updateActivity = useCallback(
    (id: string, data: ActivityType, params?: object) =>
      activitiesService.patch(id, data, params),
    [activitiesService]
  )

  const removeActivity = useCallback(
    (id: string, params?: object) => activitiesService.remove(id, params),
    [activitiesService]
  )

  const context = useMemo(
    () => ({
      activitiesService,
      findActivities,
      getActivity,
      createActivity,
      updateActivity,
      removeActivity,
    }),
    [
      activitiesService,
      findActivities,
      getActivity,
      createActivity,
      updateActivity,
      removeActivity,
    ]
  )

  return (
    <ActivitiesContext.Provider value={context}>
      {children}
    </ActivitiesContext.Provider>
  )
}
