import { ArrowDown, HambergerMenu, SearchNormal } from 'iconsax-react'
import { memo } from 'react'
import { Link } from 'react-router-dom'

import Image from 'components/atoms/Image'
import UserAvatar from 'components/molecules/User/Avatar'
import { LOCALES } from 'config/locale'
import { ROUTES } from 'config/routes'
import { useLocale } from 'providers/Locale'
import { useMenu } from 'providers/Menu'
import { useUser } from 'providers/User'

function Header() {
  const { user } = useUser()
  const { toggleMenu } = useMenu()
  const { locale, setLocale } = useLocale()

  return (
    <div className="block border-b border-zinc-100 bg-white text-zinc-900 md:hidden">
      <div className="flex h-16 w-full items-center justify-start pr-4">
        <button className="mx-5 block md:hidden" onClick={toggleMenu}>
          <HambergerMenu size={20} />
        </button>
        <Link to={`/`} className="flex">
          <Image className="h-[35px] w-auto" src="/assets/images/milma.svg" />
        </Link>
        {/* <form className="mr-2 w-full max-w-xs" data-form-type="">
          <div className="relative">
            <input
              type="search"
              name="search"
              placeholder="Search..."
              className="h-10 w-full appearance-none rounded-full border border-zinc-100 bg-zinc-100 pl-10 pr-5 text-sm focus:outline-none"
              data-form-type=""
            />
            <button
              type="submit"
              className="absolute top-0 left-0 mt-3 ml-4"
              data-form-type=""
            >
              <SearchNormal size={16} />
            </button>
          </div>
        </form> */}
        {/* <div className="relative hidden text-left lg:inline-block">
          <button
            className="flex w-full items-center justify-center px-4 py-2 text-xs font-bold uppercase focus:outline-none"
            id="headlessui-menu-button-:R9ml6:"
            type="button"
          >
            <span className="mr-1">Ajouter</span>
            <ArrowDown size={16} />
          </button>
        </div> */}
        <div className="ml-auto"></div>
        <div className="relative text-left">
          <button
            className="flex h-16 w-12 items-center justify-center"
            onClick={() => {
              setLocale(locale === LOCALES.enGB ? LOCALES.frFR : LOCALES.enGB)
            }}
          >
            <span
              className={`fi ${
                locale === LOCALES.enGB ? 'fi-fr' : 'fi-gb'
              } text-base`}
            ></span>
          </button>
        </div>
        {/* <div className="relative hidden text-left lg:inline-block">
          <button
            className="flex h-16 w-12 items-center justify-center"
            onClick={toggleTheme}
          >
            {theme === THEMES.DARK ? <Sun size={20} /> : <Moon size={20} />}
          </button>
        </div> */}
        <div className="relative text-left lg:inline-block">
          <Link
            to={`/${ROUTES.PROFILE}`}
            className="flex h-16 w-12 items-center justify-center md:hidden"
          >
            <div className="relative h-8 w-8">
              <UserAvatar userId={user?.id} />
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default memo(Header)
