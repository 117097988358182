import { get, set } from 'lodash'
import {
  lazy,
  ReactNode,
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react'

import Spin from 'components/atoms/Spin'
import { useUser } from 'providers/User'
import {
  WorkflowContext,
  WorkflowType,
} from 'providers/Workflow/WorkflowContext'

const fallback = (
  <div className="flex items-center justify-center">
    <Spin />
  </div>
)

export const WorkflowProvider = ({ children }: { children: ReactNode }) => {
  const { isAuth } = useUser()

  const [loading, setLoading] = useState<boolean>(false)
  const [loadedComponents, setLoadedComponents] = useState({})
  const [workflow, setWorkflow] = useState<WorkflowType | undefined>(undefined)
  const [data, setData] = useState<Record<string, any> | undefined>(undefined)

  const getComponent = useCallback(
    (slug: string) => {
      const component = get(loadedComponents, slug)

      if (component) {
        return component
      }

      try {
        const component = lazy(() =>
          import(`components/workflows/${slug}`).catch((error) => {
            throw new Error(error)
          })
        )

        setLoadedComponents((loadedComponents) =>
          set(loadedComponents, slug, component)
        )

        return component
      } catch (error) {
        throw new Error(`Workflow "${slug}" not found`)
      }
    },
    [loadedComponents]
  )

  const startWorkflow = useCallback(
    ({
      slug,
      data = {},
      onCompleted = () => {},
    }: {
      slug: string
      data?: Record<string, any>
      onCompleted?: (data?: any) => void
    }) => {
      if (!isAuth || loading) {
        return
      }

      setLoading(true)
      const component = getComponent(slug)
      setLoading(false)
      setWorkflow({ component, onCompleted })
      setData(data)
    },
    [isAuth, loading, getComponent]
  )

  const closeWorkflow = useCallback((): void => {
    setWorkflow(undefined)
    setData(undefined)
  }, [])

  const finishWorkflow = useCallback(
    (data: any): void => {
      if (workflow?.onCompleted) {
        workflow.onCompleted(data)
      }
    },
    [workflow]
  )

  const { component: Component } = workflow || {}

  const context = useMemo(
    () => ({
      loading,
      startWorkflow,
      closeWorkflow,
      finishWorkflow,
      data,
    }),
    [loading, startWorkflow, closeWorkflow, finishWorkflow, data]
  )

  return (
    <WorkflowContext.Provider value={context}>
      {children}
      {
        <Suspense fallback={workflow ? null : fallback}>
          {Component && <Component />}
        </Suspense>
      }
    </WorkflowContext.Provider>
  )
}
