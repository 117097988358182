import { memo } from 'react'

import Tooltip from 'components/atoms/Tooltip'
import { PriceType } from 'config/price'
import { useFormatPrice, usePrecisePrice } from 'hooks/price'
import { isSet } from 'utils'

const Price = ({
  value,
  withPrecision,
}: {
  value?: PriceType | null
  withPrecision?: boolean
}) => {
  const formatPrice = useFormatPrice()
  const precisePrice = usePrecisePrice()

  const formatValue: string = formatPrice(value)
  const preciseValue: string = precisePrice(value)

  if (
    withPrecision &&
    isSet(value) &&
    preciseValue &&
    preciseValue !== formatValue
  ) {
    return <Tooltip title={preciseValue}>{formatValue}</Tooltip>
  }

  return <>{formatValue}</>
}

export default memo(Price)
