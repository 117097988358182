import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'

import 'styles/general.scss'

import App from 'App'
import { ENV, NODE_ENV } from 'config/env'
import reportWebVitals from 'reportWebVitals'
import { isProdEnv } from 'utils/env'

Sentry.init({
  enabled: isProdEnv,
  environment: NODE_ENV || ENV.DEV,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  tracesSampleRate: isProdEnv ? 0.2 : 1.0,
  integrations: [new BrowserTracing()],
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <StrictMode>
    <App />
  </StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
