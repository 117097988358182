import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

import { MandateType } from 'config/mandates'
import { SERVICES } from 'config/services'
import { useMandates } from 'providers/Mandates'

export const useMandateName = (mandateId?: string) => {
  const { getMandate } = useMandates()

  const result = useQuery<MandateType>({
    enabled: Boolean(mandateId),
    queryKey: [SERVICES.MANDATES, mandateId],
    queryFn: () => getMandate(mandateId as string),
  })

  return useMemo(
    () => ({
      ...result,
      name: result?.data?.name,
    }),
    [result]
  )
}
