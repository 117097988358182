import feathers, { authentication, rest } from '@feathersjs/client'
import { ReactNode, useMemo } from 'react'

import { LOCAL_STORAGE } from 'config/localStorage'
import { FeathersContext } from 'providers/Feathers/FeathersContext'

export const FeathersProvider = ({ children }: { children: ReactNode }) => {
  // @ts-ignore
  const client = feathers()

  client.configure(rest(process.env.REACT_APP_API_ENDPOINT).fetch(window.fetch))

  client.configure(
    authentication({
      storage: window.localStorage,
      storageKey: LOCAL_STORAGE.TOKEN_ID,
    })
  )

  const addJWT = () => async (context: any) => {
    if (localStorage.getItem(LOCAL_STORAGE.TOKEN_ID)) {
      context.params.headers = {
        ...context.params.headers,
        Authorization: `Bearer ${localStorage.getItem(LOCAL_STORAGE.TOKEN_ID)}`,
      }
    }

    return context
  }

  const updateJWT = () => async (context: any) => {
    const { result } = context

    if (result.newAccessToken) {
      client.authentication.setAccessToken(result.newAccessToken)
      localStorage.setItem(LOCAL_STORAGE.TOKEN_ID, result.newAccessToken)

      context.result = result.data
    }

    return context
  }

  client.hooks({
    before: {
      all: [addJWT()],
    },
    after: {
      all: [updateJWT()],
    },
  })

  const context = { client }

  return (
    <FeathersContext.Provider value={context}>
      {children}
    </FeathersContext.Provider>
  )
}
