export enum SLUGS {
  COMPLETE_MANDATE = 'completeMandate',
  EDIT_ACTIVITY = 'editActivity',
  EDIT_BENEFIT = 'editBenefit',
  EDIT_CONTACT = 'editContact',
  EDIT_IMMERSION = 'editImmersion',
  EDIT_MANDATE = 'editMandate',
  EDIT_OPINION = 'editOpinion',
  EDIT_SEARCH = 'editSearch',
  EDIT_USER = 'editUser',
  SEND_MENTORSHIP = 'sendMentorship',
}
