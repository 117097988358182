import { ReactNode, useCallback, useMemo } from 'react'

import { SearchType } from 'config/searches'
import { SERVICES } from 'config/services'
import { useFeathers } from 'providers/Feathers'
import { SearchesContext } from 'providers/Searches/SearchesContext'

export const SearchesProvider = ({ children }: { children: ReactNode }) => {
  const { client } = useFeathers()

  const searchesService = client.service(SERVICES.SEARCHES)

  const findSearches = useCallback(
    (params?: object) => searchesService.find(params),
    [searchesService]
  )

  const getSearch = useCallback(
    (id: string, params?: object) => searchesService.get(id, params),
    [searchesService]
  )

  const createSearch = useCallback(
    (data: SearchType, params?: object) => searchesService.create(data, params),
    [searchesService]
  )

  const updateSearch = useCallback(
    (id: string, data: SearchType, params?: object) =>
      searchesService.patch(id, data, params),
    [searchesService]
  )

  const removeSearch = useCallback(
    (id: string, params?: object) => searchesService.remove(id, params),
    [searchesService]
  )

  const context = useMemo(
    () => ({
      searchesService,
      findSearches,
      getSearch,
      createSearch,
      updateSearch,
      removeSearch,
    }),
    [
      searchesService,
      findSearches,
      getSearch,
      createSearch,
      updateSearch,
      removeSearch,
    ]
  )

  return (
    <SearchesContext.Provider value={context}>
      {children}
    </SearchesContext.Provider>
  )
}
