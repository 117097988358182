import { ReactNode, useCallback, useMemo } from 'react'

import { OpinionType } from 'config/opinions'
import { SERVICES } from 'config/services'
import { useFeathers } from 'providers/Feathers'
import { OpinionsContext } from 'providers/Opinions/OpinionsContext'

export const OpinionsProvider = ({ children }: { children: ReactNode }) => {
  const { client } = useFeathers()

  const opinionsService = client.service(SERVICES.OPINIONS)

  const findOpinions = useCallback(
    (params?: object) => opinionsService.find(params),
    [opinionsService]
  )

  const getOpinion = useCallback(
    (id: string, params?: object) => opinionsService.get(id, params),
    [opinionsService]
  )

  const createOpinion = useCallback(
    (data: OpinionType, params?: object) =>
      opinionsService.create(data, params),
    [opinionsService]
  )

  const updateOpinion = useCallback(
    (id: string, data: OpinionType, params?: object) =>
      opinionsService.patch(id, data, params),
    [opinionsService]
  )

  const removeOpinion = useCallback(
    (id: string, params?: object) => opinionsService.remove(id, params),
    [opinionsService]
  )

  const context = useMemo(
    () => ({
      opinionsService,
      findOpinions,
      getOpinion,
      createOpinion,
      updateOpinion,
      removeOpinion,
    }),
    [
      opinionsService,
      findOpinions,
      getOpinion,
      createOpinion,
      updateOpinion,
      removeOpinion,
    ]
  )

  return (
    <OpinionsContext.Provider value={context}>
      {children}
    </OpinionsContext.Provider>
  )
}
