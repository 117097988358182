import React, { Dispatch, SetStateAction, useContext } from 'react'

export interface IMenuContext {
  menu: string
  setMenu: Dispatch<SetStateAction<string>>
  toggleMenu: () => void
}

export const MenuContext = React.createContext<IMenuContext>({
  menu: 'false',
  setMenu: () => {},
  toggleMenu: () => {},
})

export const useMenu = (): IMenuContext => useContext(MenuContext)
