import { memo, useMemo, lazy, Suspense } from 'react'

import { useDrawer } from 'providers/Drawer/DrawerContext'
import { useUser } from 'providers/User'

import { UserDrawerTab } from '..'

const Details = lazy(() => import('components/drawers/User/Content/Details'))
const Documents = lazy(
  () => import('components/drawers/User/Content/Documents')
)

const Content = () => {
  const { tab } = useDrawer()
  const { isAdmin } = useUser()

  const Component = useMemo(() => {
    switch (tab) {
      case UserDrawerTab.documents:
        return isAdmin ? Documents : undefined
      default:
        return Details
    }
  }, [tab, isAdmin])

  if (!Component) {
    return null
  }

  return (
    <Suspense fallback={null}>
      <Component />
    </Suspense>
  )
}

export default memo(Content)
