import { createContext, useContext } from 'react'

import { SendMentorshipType } from 'config/sendMentorships'

interface ISendMentorshipsContext {
  sendMentorshipsService: any
  sendMentorship: (
    data: SendMentorshipType,
    params?: object
  ) => Promise<SendMentorshipType>
}

export const SendMentorshipsContext = createContext<ISendMentorshipsContext>({
  sendMentorshipsService: {},
  sendMentorship: () => Promise.resolve({}),
})

export const useSendMentorships = (): ISendMentorshipsContext =>
  useContext(SendMentorshipsContext)
