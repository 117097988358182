import {
  AddSquare,
  Briefcase,
  BrifecaseTick,
  Calculator,
  Category,
  CloseCircle,
  House2,
  Logout,
  Notepad2,
  People,
  Personalcard,
  SearchZoomIn,
  Setting2,
  Shop,
  Teacher,
  User,
} from 'iconsax-react'
import { memo, useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'

import Button from 'components/atoms/Button'
import Image from 'components/atoms/Image'
import Message from 'components/atoms/Intl/Message'
import Price from 'components/atoms/Intl/Price'
import Menu from 'components/atoms/Menu'
import Tag from 'components/atoms/Tag'
import Tooltip from 'components/atoms/Tooltip'
import UserAvatar from 'components/molecules/User/Avatar'
import UserName from 'components/molecules/User/Name'
import UserRole from 'components/molecules/User/Role'
import { IMMERSION_STATUS } from 'config/immersionStatus'
import { ROLES } from 'config/roles'
import { ROUTES } from 'config/routes'
import { SLUGS } from 'config/slugs'
import { useUserImmersionIds } from 'hooks/User/useUserImmersionIds'
import { useMenu } from 'providers/Menu'
import { useUser } from 'providers/User'
import { useWorkflow } from 'providers/Workflow'
import { cleanArray } from 'utils'

const crmRoutesKeys = [ROUTES.CONTACTS, ROUTES.SEARCHES, ROUTES.MANDATES]
const adminRoutesKeys = [
  ROUTES.ADMIN_IMMERSIONS,
  ROUTES.ADMIN_MANDATES,
  ROUTES.ADMIN_USERS,
]
const SALES_CURRENT = 0 // TODO: do this automatically
const SALES_TARGET = 40000

function Sider() {
  const location = useLocation()
  const { toggleMenu } = useMenu()
  const { startWorkflow } = useWorkflow()
  const { user, isMandatory, isAdmin, isMaestro, isRoleIn } = useUser()
  const { immersionIds } = useUserImmersionIds(user?.id, {
    status: IMMERSION_STATUS.PENDING,
    maestroId: user?.id,
  })

  const defaultKey = useMemo(
    () => location?.pathname?.match(/^\/(.+?)(?:\/.+)?$/)?.[1] || 'dashboard',
    [location]
  )

  const defaultOpenKeys = useMemo(() => {
    if (crmRoutesKeys.includes(defaultKey as ROUTES)) {
      return ['crm']
    }

    if (adminRoutesKeys.includes(defaultKey as ROUTES)) {
      return ['admin']
    }

    return undefined
  }, [defaultKey])

  const menu = useMemo(
    () =>
      cleanArray([
        isRoleIn([
          ROLES.ADMIN,
          ROLES.MAESTRO,
          ROLES.COACH,
          ROLES.MANDATORY,
        ]) && {
          icon: <Category size={20} />,
          message: 'word.dashboard',
          to: '/',
          key: 'dashboard',
        },
        isRoleIn([
          ROLES.ADMIN,
          ROLES.MAESTRO,
          ROLES.COACH,
          ROLES.MANDATORY,
        ]) && {
          icon: <People size={20} />,
          message: 'word.myNetwork',
          to: `/${ROUTES.NETWORK}`,
          key: ROUTES.NETWORK,
        },
        isMaestro && {
          icon: <BrifecaseTick size={20} />,
          message: (
            <>
              <Message value="word.immersions" />
              {immersionIds && immersionIds?.length > 0 && (
                <Tooltip title="immersionStatus.PENDING">
                  <Tag
                    color="#EF216C"
                    message={String(immersionIds.length)}
                    style={{
                      marginLeft: '8px',
                      transform: 'scale(0.85)',
                    }}
                  />
                </Tooltip>
              )}
            </>
          ),
          to: `/${ROUTES.IMMERSIONS}`,
          key: ROUTES.IMMERSIONS,
        },
        isMandatory && {
          icon: <House2 size={20} />,
          message: 'word.publicMandates',
          to: `/${ROUTES.PUBIC_MANDATES}`,
          key: ROUTES.PUBIC_MANDATES,
        },
        isMandatory && {
          icon: <Briefcase size={20} />,
          key: 'crm',
          title: 'word.crm',
          items: [
            {
              icon: <Personalcard size={20} />,
              message: 'word.myContacts',
              to: `/${ROUTES.CONTACTS}`,
              key: ROUTES.CONTACTS,
            },
            {
              icon: <SearchZoomIn size={20} />,
              message: 'word.mySearches',
              to: `/${ROUTES.SEARCHES}`,
              key: ROUTES.SEARCHES,
            },
            {
              icon: <Notepad2 size={20} />,
              message: 'word.myMandates',
              to: `/${ROUTES.MANDATES}`,
              key: ROUTES.MANDATES,
            },
          ],
        },
        isAdmin && {
          icon: <Setting2 size={20} />,
          key: 'admin',
          title: 'word.administration',
          items: [
            isAdmin && {
              icon: <User size={20} />,
              message: 'word.users',
              to: `/${ROUTES.ADMIN_USERS}`,
              key: 'admin-users',
            },
            {
              icon: <Notepad2 size={20} />,
              message: 'word.mandates',
              to: `/${ROUTES.ADMIN_MANDATES}`,
              key: 'admin-mandates',
            },
            {
              icon: <BrifecaseTick size={20} />,
              message: 'word.immersions',
              to: `/${ROUTES.ADMIN_IMMERSIONS}`,
              key: 'admin-immersions',
            },
          ],
        },
        isRoleIn([
          ROLES.ADMIN,
          ROLES.MAESTRO,
          ROLES.COACH,
          ROLES.MANDATORY,
        ]) && {
          icon: <Shop size={20} />,
          message: 'word.shop',
          to: `/${ROUTES.SHOP}`,
          key: 'shop',
        },
        isRoleIn([
          ROLES.ADMIN,
          ROLES.MAESTRO,
          ROLES.COACH,
          ROLES.MANDATORY,
        ]) && {
          icon: <Teacher size={20} />,
          message: 'word.trainings',
          to: `/${ROUTES.TRAININGS}`,
          key: 'trainings',
        },
        isRoleIn([ROLES.ADMIN, ROLES.MAESTRO, ROLES.MANDATORY]) && {
          icon: <Calculator size={20} />,
          message: 'word.toolbox',
          to: `/${ROUTES.TOOLBOX}`,
          key: 'toolbox',
        },
        isRoleIn([ROLES.ADMIN]) && {
          icon: <Logout size={20} />,
          message: 'word.logOut',
          to: `/${ROUTES.LOGOUT}`,
          key: 'logout',
        },
      ]),
    [isMandatory, isAdmin, immersionIds, isMaestro, isRoleIn]
  )

  return (
    <div className="left-sidebar fixed z-50 flex h-full min-w-[250px] max-w-[250px] flex-col space-y-5 overflow-hidden border-r border-zinc-100 bg-white p-4 text-zinc-900">
      <div className="flex flex-row items-center justify-between space-x-2 pt-2">
        <Link to={`/`}>
          <Image className="mx-1 max-h-[45px]" src="/assets/images/milma.svg" />
        </Link>
        <button className="ml-auto mr-4 block md:hidden" onClick={toggleMenu}>
          <CloseCircle size={20} />
        </button>
      </div>
      <div className="px-3">
        <Link
          to={`/${ROUTES.PROFILE}`}
          className={`flex cursor-pointer flex-row items-center justify-center space-x-2 rounded-xl border border-[#F0F0F0] p-1 transition duration-200 hover:border-[#EF216C] hover:bg-[#FEF1F3] ${
            defaultKey === ROUTES.PROFILE ? 'border-[#EF216C] bg-[#FEF1F3]' : ''
          }`}
        >
          <UserAvatar size="large" userId={user?.id} />
          <div className="flex-1 flex-row">
            <p className="font-bold">
              <UserName userId={user?.id} />
            </p>
            <p className="text-xs text-zinc-500">
              <UserRole userId={user?.id} />
            </p>
          </div>
        </Link>
      </div>
      {user?.isValidated &&
      isRoleIn([ROLES.MAESTRO, ROLES.COACH, ROLES.MANDATORY]) ? (
        <div className="px-3">
          <Button
            style={{
              background: 'linear-gradient(270deg, #BD1C62 0%, #EF216C 100%)',
            }}
            className="min-w-full rounded-full text-xs font-bold"
            icon={<AddSquare size={16} />}
            type="primary"
            message="word.addMentorship"
            onClick={() =>
              startWorkflow({
                slug: SLUGS.SEND_MENTORSHIP,
              })
            }
          />
        </div>
      ) : undefined}
      <div>
        <Menu
          mode="inline"
          className="border-none"
          selectedKeys={defaultKey ? [defaultKey] : undefined}
          defaultOpenKeys={defaultOpenKeys}
          items={menu}
        />
      </div>
      <div className="flex-1"></div>
      {isMandatory && Boolean(SALES_CURRENT) && (
        <div className="px-3">
          <p>
            <span className="text-lg font-bold">
              <Price value={SALES_CURRENT} />
            </span>
            <span className="text-xs">
              {' '}
              / <Price value={SALES_TARGET} />
            </span>
          </p>
          <p className="font-bold">
            <Message value="word.salesTarget" />
          </p>
          <div className="mt-2 h-3 w-full rounded-full bg-[#efefef]">
            <div
              style={{
                width: `${(SALES_CURRENT / SALES_TARGET) * 100}%`,
                background: 'linear-gradient(270deg, #BD1C62 0%, #EF216C 100%)',
              }}
              className="mt-2 h-3 rounded-full"
            ></div>
          </div>
        </div>
      )}
      <div>
        <div className="text-center">
          <span className="text-xs text-zinc-500">
            © Milma {new Date().getFullYear()}
          </span>
        </div>
      </div>
    </div>
  )
}

export default memo(Sider)
