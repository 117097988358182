import { createContext, useContext } from 'react'

import { FindNetworkType } from 'config/network'

interface INetworkContext {
  NetworkService: any
  getNetwork: (id: string, params?: object) => Promise<FindNetworkType>
}

export const NetworkContext = createContext<INetworkContext>({
  NetworkService: {},
  getNetwork: () =>
    Promise.resolve({
      rowCount: 0,
      rows: [],
    }),
})

export const useNetwork = (): INetworkContext => useContext(NetworkContext)
