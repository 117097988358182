import { memo, useMemo } from 'react'

import Drawer from 'components/atoms/Drawer'
import Message from 'components/atoms/Intl/Message'
import Space from 'components/atoms/Space'
import Tooltip from 'components/atoms/Tooltip'
import Content from 'components/drawers/User/Content'
import TagUserRole from 'components/molecules/Tag/UserRole'
import UserAvatar from 'components/molecules/User/Avatar'
import UserName from 'components/molecules/User/Name'
import UserRole from 'components/molecules/User/Role'
import { ROLES } from 'config/roles'
import { useUserFirstName } from 'hooks/User/useUserFirstName'
import { useUserLastName } from 'hooks/User/useUserLastName'
import { useUserRole } from 'hooks/User/useUserRole'
import { useDrawer } from 'providers/Drawer'

export enum UserDrawerTab {
  details = 'details',
  documents = 'documents',
}

function User() {
  const { id } = useDrawer()
  const { firstName } = useUserFirstName(id)
  const { lastName } = useUserLastName(id)
  const { role } = useUserRole(id)

  const color = role
    ? {
        [ROLES.ADMIN]: '#EF216C',
        [ROLES.MAESTRO]: '#EF216C',
        [ROLES.COACH]: '#1C5DBD',
        [ROLES.MANDATORY]: '#6EB655',
      }[role]
    : '#252525'

  return (
    <Drawer
      open
      drawerWidth={'400px'}
      helmetTitle={firstName && lastName && `${firstName} ${lastName}`}
      title={
        <Space direction="vertical" size={4}>
          <span className="text-2xl font-normal">
            <UserName withId userId={id} />
          </span>
          <Tooltip
            placement="left"
            color={color}
            title={
              <div className="p-1">
                <UserRole userId={id} />
                <div className="text-xs">
                  <Message value={`tooltipRoleTypes.${role}`} />
                </div>
              </div>
            }
          >
            <TagUserRole userId={id} />
          </Tooltip>
        </Space>
      }
      extra={<UserAvatar className="ml-3" size={70} userId={id} />}
      content={Content}
    />
  )
}

export default memo(User)
