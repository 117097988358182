import { memo } from 'react'

import Drawer from 'components/atoms/Drawer'
import Content from 'components/drawers/Mandate/Content'
import { useMandateName } from 'hooks/Mandate/useMandateName'
import { useDrawer } from 'providers/Drawer'

export enum MandateDrawerTab {
  details = 'details',
}

function Mandate() {
  const { id } = useDrawer()
  const { name } = useMandateName(id)

  return (
    <Drawer
      open
      drawerWidth={'1200px'}
      helmetTitle={name && name}
      content={Content}
    />
  )
}

export default memo(Mandate)
