import { FormatNumberOptions, useIntl } from 'react-intl'
import { isArgumentsObject } from 'util/types'

import { PriceType } from 'config/price'
import { isUnset } from 'utils'

import { DEFAULT_CURRENCY } from './../config/currency'

export const useFormatPrice = (options: FormatNumberOptions = {}) => {
  const intl = useIntl()

  return (price?: PriceType | null) => {
    if (isUnset(price)) {
      return ''
    }

    return intl.formatNumber(price, {
      style: 'currency',
      currency: DEFAULT_CURRENCY,
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
      ...options,
    })
  }
}

export const usePrecisePrice = () => {
  const intl = useIntl()

  return (price?: PriceType | null) => {
    if (isUnset(price)) {
      return ''
    }

    return intl.formatNumber(price, {
      style: 'currency',
      currency: DEFAULT_CURRENCY,
      minimumFractionDigits: 0,
      maximumFractionDigits: 4,
    })
  }
}
