import { ReactNode, useCallback, useMemo } from 'react'

import { BenefitType } from 'config/benefits'
import { SERVICES } from 'config/services'
import { BenefitsContext } from 'providers/Benefits/BenefitsContext'
import { useFeathers } from 'providers/Feathers'

export const BenefitsProvider = ({ children }: { children: ReactNode }) => {
  const { client } = useFeathers()

  const benefitsService = client.service(SERVICES.BENEFITS)

  const findBenefits = useCallback(
    (params?: object) => benefitsService.find(params),
    [benefitsService]
  )

  const getBenefit = useCallback(
    (id: string, params?: object) => benefitsService.get(id, params),
    [benefitsService]
  )

  const createBenefit = useCallback(
    (data: BenefitType, params?: object) =>
      benefitsService.create(data, params),
    [benefitsService]
  )

  const updateBenefit = useCallback(
    (id: string, data: BenefitType, params?: object) =>
      benefitsService.patch(id, data, params),
    [benefitsService]
  )

  const removeBenefit = useCallback(
    (id: string, params?: object) => benefitsService.remove(id, params),
    [benefitsService]
  )

  const context = useMemo(
    () => ({
      benefitsService,
      findBenefits,
      getBenefit,
      createBenefit,
      updateBenefit,
      removeBenefit,
    }),
    [
      benefitsService,
      findBenefits,
      getBenefit,
      createBenefit,
      updateBenefit,
      removeBenefit,
    ]
  )

  return (
    <BenefitsContext.Provider value={context}>
      {children}
    </BenefitsContext.Provider>
  )
}
